<template>
  <div
    v-loading="filterLoading"
    :element-loading-text="loadingText"
    class="custom-dashboard-table"
  >
    <!-- <h3>Custom filters</h3> -->
    <el-scrollbar>
      <div v-if="allDashboardstats" class="stats-container mb-1 mt-1">
        <el-popover
          v-if="allDashboardstats.includeGlobalDateFilter"
          placement="top-start"
          title="Filter applied on:"
          width="200"
          trigger="hover"
        >
          <div slot="reference">
            <el-select
              v-if="allDashboardstats.includeGlobalDateFilter"
              placeholder="Pick date range"
              v-model="dateFilter.selected_date_picker"
              :size="'mini'"
              @change="setDatePickerDate"
              clearable
            >
              <el-option
                v-for="(el, index) in pickerOptions"
                :key="el.value + '_' + index"
                :value="el.value"
                :label="el.label"
                >{{ el.label }}
              </el-option>
            </el-select>
          </div>
          <div>
            <ul v-for="(op, i) in getStatsLabel" :key="i + '_op'">
              {{
                i + 1
              }}.{{
                op.component_name
              }}
            </ul>
          </div>
        </el-popover>
        <el-date-picker
          v-if="dateFilter.selected_date_picker == 'custom_date'"
          v-model="dateFilter.custom_date"
          size="mini"
          placeholder="Select custom data"
          @change="applyCustomDateFilter"
          format="MM-dd-yyyy"
        >
        </el-date-picker>
        <el-date-picker
          v-if="dateFilter.selected_date_picker == 'custom_date_range'"
          v-model="dateFilter.custom_date_range"
          size="mini"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          type="daterange"
          @change="applyCustomDateRangeFilter"
          format="MM-dd-yyyy"
        >
        </el-date-picker>
        <el-date-picker
          v-if="dateFilter.selected_date_picker == 'month'"
          size="mini"
          v-model="dateFilter.selected_month"
          type="month"
          placeholder="Select month"
          @change="applyMonthFilter"
        >
        </el-date-picker>
        <div
          class="filter-card"
          style="min-width: 150px"
          v-for="(filter, index) in allDashboardstats.allCustomFilters"
          :key="
            filter.entity_id +
            'dashboard_filter' +
            (filter.keyValue ? filter.keyValue : 'key') +
            index
          "
        >
          <CustomEntityFilter
            v-if="filter.filter_field_type === 'ENTITY'"
            :field="filter"
            :fieldPlaceholder="getFilterPlaceholder(filter.filter_field)"
            :size="'mini'"
            :isMultiple="true"
            :filters="
              filter.is_relational_data ? applyRelationalFilter([]) : []
            "
            :primary_fields="getPrimaryField(filter.filter_field)"
            @entityFilterUpdated="entityFilterUpdated"
          >
          </CustomEntityFilter>
          <el-select
            v-else-if="
              filter.filter_field_type !== 'DATE' && filter.filter_field
            "
            :placeholder="getFilterPlaceholder(filter.filter_field)"
            v-model="filter.selected_filter"
            @change="applyCustomFilter(filter, filter.selected_filter)"
            clearable
            size="mini"
            multiple
            collapse-tags
          >
            <el-option
              v-for="(op, index) in getFilterOptions(filter.filter_field)"
              :key="index + '_' + op"
              :value="op"
              :label="op"
            >
              {{ op }}
            </el-option>
          </el-select>
        </div>
        <div
          class="filter-card"
          style="min-width: 150px"
          v-for="(filter, index) in allDashboardstats.allMulCustomFilters"
          :key="
            filter.entity_id +
            'dashboard_filter' +
            (filter.keyValue ? filter.keyValue : 'key') +
            index
          "
        >
          <CustomMulEntityFilter
            v-if="allDashboardstats.allMulCustomFilters.length"
            :allMultiFilters="allDashboardstats.allMulCustomFilters"
            :filter="filter"
            :fieldPlaceholder="
              getFilterPlaceholder(filter.entities[0].filter_field)
            "
            :size="'mini'"
            :isMultiple="true"
            :filters="
              filter.is_relational_data ? applyRelationalFilter([]) : []
            "
            :filterValues="filterValues"
            @mulEntityFilterUpdated="mulEntityFilterUpdated"
          >
          </CustomMulEntityFilter>
        </div>
        <div
          class="filter-card"
          style="min-width: 150px"
          v-for="(filter, index) in allDashboardstats.allIntegrationFilters"
          :key="'allIntegrationFilters' + index"
        >
          <el-select
            :placeholder="'Select ' + filter.dependency"
            v-model="filter.default_value"
            @change="applyGlobalFilters(filter, filter.default_value)"
            size="mini"
            filterable
          >
            <el-option
              v-for="(op, index) in dependenciesData &&
              filter.integration_id &&
              dependenciesData[filter.integration_id] &&
              dependenciesData[filter.integration_id][filter.dependency]
                ? dependenciesData[filter.integration_id][filter.dependency]
                : []"
              :key="index + '_' + op.id"
              :value="op.id"
              :label="op.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import moment from "moment";
import { bus } from "../../main";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  mixins: [CustomDashboardConfig],
  props: {
    allDashboardstats: Object,
    getAllCompanyTemplatesData: Object,
    predefinedIntegrations: Array,
    integrations: Array,
  },
  computed: {
    // ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    getStatsLabel() {
      if (this.allDashboardstats?.allStatComponents) {
        return this.allDashboardstats.allStatComponents.filter(
          (e) => e.date_filter_field
        );
      }
      return [];
    },
  },
  components: {
    // CustomDatePicker: () => import("@/components/widgets/customDatePicker.vue"),
    CustomEntityFilter: () => import("./customDashboardEntityFilter.vue"),
    CustomMulEntityFilter: () => import("./customDashboardMulEntityFilter.vue"),
  },
  async mounted() {
    this.setAllFieldsOptions();
    if (
      this.allDashboardstats?.allIntegrationFilters &&
      this.allDashboardstats.allIntegrationFilters.length
    ) {
      this.setDependencyFields();
    }
    if(this.allDashboardstats.date_value){
      this.dateFilter.selected_date_picker = this.allDashboardstats.date_value;
      this.setDatePickerDate(this.dateFilter.selected_date_picker)
      if(this.allDashboardstats.Custom_date_value){
        if(this.dateFilter.selected_date_picker == 'custom_date')
        {
         this.dateFilter.custom_date=this.allDashboardstats.Custom_date_value[0]
         this.applyCustomDateFilter( this.dateFilter.custom_date)
        }
        else if(this.dateFilter.selected_date_picker == 'custom_date_range'){
          this.dateFilter.custom_date_range=this.allDashboardstats.Custom_date_value
          this.applyCustomDateRangeFilter(this.dateFilter.custom_date_range)
        }
        else if(this.dateFilter.selected_date_picker == 'month'){
          this.dateFilter.selected_month=this.allDashboardstats.Custom_date_value[0]
          this.applyMonthFilter(this.dateFilter.selected_month)
        }
      }
    }
  },
  methods: {
    getDependencies(selectedApplication) {
      if (!selectedApplication) {
        return [];
      }
      let dependencies = [];
      // dependencies = [...selectedApplication.dependencies].map((e) => {
      //   e.dependencyField = [e.internal_dependency_field];
      //   return e;
      // });
      selectedApplication.events.forEach((evt) => {
        if (evt.settings.requiredFields) {
          dependencies = [...dependencies, ...evt.settings.requiredFields];
        }
      });
      return dependencies;
    },
    setDependencyFields() {
      this.allDashboardstats.allIntegrationFilters.map((filter) => {
        let selectedApplication = this.predefinedIntegrations.find(
          (e) => e.key == filter.appName
        );
        let dependencies = this.getDependencies(selectedApplication);

        if (dependencies.length) {
          let hasDependency = dependencies.find(
            (f) => f.key == filter.dependency && f.dependencyField
          );

          if (hasDependency?.key) {
            this.$set(
              this.dependencyFilters,
              filter.integration_id + "#" + filter.dependency,
              true
            );
          }
        }
      });
    },
    applyGlobalFilters(filter, default_value) {
      bus.$emit("apply-global-filter", {
        filter,

        value: default_value,
      });
    },
    applyCustomDateFilter(value) {
      this.$emit('update:CustomDate',value)
      if (value) {
        // let dateValue = this.getDateByDays(0, value);
        let dateValue = moment(value).utc();
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: "custom_date",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: dateValue,
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([{ ...filter, ...{ value: dateValue } }]),
        });
      } else {
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    applyCustomDateRangeFilter(value) {
      this.$emit('update:CustomDate',value)
      if (value.length) {
        // let dateValue = this.getDateByDays(0, value[0]);
        // let dateValue2 = this.getDateByDays(0, value[1]);
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: "custom_date_range",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: value,
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([filter]),
        });
      } else {
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    getMonthDate(inputDate, isEndofTheMonth) {
      const year = moment(inputDate).year();
      const month = moment(inputDate).month();

      let date;
      if (isEndofTheMonth) {
        date = moment([year, month]).endOf("month");
      } else {
        date = moment([year, month]).startOf("month");
      }
      return date;
    },
    applyMonthFilter(value) {
      this.$emit('update:CustomDate',value)
      if (value) {
        let r1 = this.getMonthDate(value, false);
        let r2 = this.getMonthDate(value, true);
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: "custom_date_range",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: [r1, r2],
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([filter]),
        });
      } else {
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    setDatePickerDate(value) {
      this.$emit('update:selectedDatePicker', value);
      if (value) {
        if (
          value == "custom_date" ||
          value == "custom_date_range" ||
          value == "month"
        ) {
          return;
        }
        let data = this.getDatePickerHelper(value);
        let filter = {
          data_type: "DATE",
          field: "",
          operator: "real_time",
          selected_date_picker: value,
          value: data.value,
          today: data.today,
          value_field: "",
          value_source: "",
          query_type: "AND",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
        };
        bus.$emit("apply-date-filter", {
          filter: this.mapDateFilters([filter]),
        });
      } else {
        this.dateFilter.selected_month = "";
        bus.$emit("apply-date-filter", {
          reset: true,
        });
      }
    },
    entityFilterUpdated(data) {
      if (data && data.entity_id) {
        if (data.value && data.value.length) {
          const selectedEntities =
            this.allDashboardstats.allCustomFilters.filter(
              (e) => e.entity_id == data.entity_id
            );
          if (
            selectedEntities &&
            selectedEntities.length &&
            data.value &&
            data.filter
          ) {
            let filters = [
              {
                data_type: "ENTITY",
                field: data.filter.filter_field,
                operator: "in",
                value: data.value,
                value_field: "",
                value_source: "",
                query_type: "AND",
                validations: {
                  data_type: "",
                },
                value_type: "VALUE",
                entity_id: data.entity_id,
              },
            ];
            bus.$emit("apply-custom-filter", {
              fields: this.mapDateFilters(filters),
            });
          } else {
            bus.$emit("apply-custom-filter", {
              reset: true,
              entity_id: data.entity_id,
            });
          }
        } else {
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: data.entity_id,
            field_id: data.filter.filter_field,
          });
        }
      } else {
        bus.$emit("apply-custom-filter", {
          reset: true,
        });
      }
    },
    mulEntityFilterUpdated(data) {
      if (data && data.filter.entities) {
        if (data.value && data.value.length) {
          const dataEntityIds = data.filter.entities.map(
            (entity) => entity.entity_id
          );
          const selectedEntities = this.allDashboardstats.allMulCustomFilters
            .map(
              (mulCustomFilter) =>
                mulCustomFilter.entities
                  .filter((entity) => dataEntityIds.includes(entity.entity_id))
                  .map((entity) => entity.entity_id) // Store the matching entity_id
            )
            .flat();
          if (
            selectedEntities &&
            selectedEntities.length &&
            data.value &&
            data.filter
          ) {
            let filters = data.filter.entities.map((entity) => {
              return {
                data_type: "ENTITY",
                field: entity.filter_field,
                operator: "in",
                value: data.value,
                value_field: "",
                value_source: "",
                query_type: "AND",
                validations: {
                  data_type: "",
                },
                value_type: "VALUE",
                entity_id: entity.entity_id,
              };
            });
            bus.$emit("apply-custom-filter", {
              fields: this.mapDateFilters(filters),
            });
          } else {
            let entities;
            data.filter.entities.forEach((key) => {
              entities = key.entity_id;
            });
            bus.$emit("apply-custom-filter", {
              reset: true,
              entity_id: entities,
            });
          }
        } else {
          let entity_ids = [];
          let field_ids = [];
          data.filter.entities.forEach((key) => {
            entity_ids.push(key.entity_id), field_ids.push(key.filter_field);
          });
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: entity_ids,
            field_id: field_ids,
          });
        }
      } else {
        bus.$emit("apply-custom-filter", {
          reset: true,
        });
      }
      if (data?.filter?.keyValue) {
        this.$set(this.filterValues, data.filter.keyValue, data.value);
      }
    },
    dateFilterUpdated(data) {
      if (data && data.entity_id) {
        const selectedEntities = this.allDashboardstats.allCustomFilters.filter(
          (e) => e.entity_id == data.entity_id
        );
        if (
          selectedEntities &&
          selectedEntities.length &&
          data.operator &&
          data.filter &&
          (data.value || data.today)
        ) {
          let filters = [
            {
              data_type: "DATE",
              field: data.filter.filter_field,
              operator: "real_time",
              selected_date_picker: data.operator,
              value: data.value,
              today: data.today,
              value_field: "",
              value_source: "",
              query_type: "AND",
              validations: {
                data_type: "",
              },
              value_type: "VALUE",
              entity_id: data.entity_id,
            },
          ];
          bus.$emit("apply-custom-filter", {
            fields: this.mapDateFilters(filters),
          });
        } else {
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: data.entity_id,
          });
        }
      }
    },
    getFilterPlaceholder(key) {
      let data = this.getFilterFieldOptions(key);
      return `By ${data && data.label ? data.label : "field"}`;
    },
    getPrimaryField(key) {
      let data = this.allTemplateSelectFields.find((e) => e.key == key);
      return data.primary_fields;
    },
    getFilterOptions(key) {
      let data = this.getFilterFieldOptions(key);
      return data && data.options ? data.options : [];
    },
    applyCustomFilter(key, value) {
      if (key && key.entity_id) {
        if (value && value.length > 0) {
          const selectedEntities =
            this.allDashboardstats.allCustomFilters.filter(
              (e) => e.entity_id == key.entity_id && e.selected_filter
            );
          if (selectedEntities && selectedEntities.length) {
            let filters = [];
            selectedEntities.forEach((el) => {
              if (el.selected_filter && el.selected_filter.length) {
                filters.push({
                  field: el.filter_field,
                  operator: "in",
                  value:
                    typeof el.selected_filter == "object"
                      ? el.selected_filter
                      : [el.selected_filter],
                  value_type: "",
                  value_source: "",
                  data_type: "STRING",
                  query_type: "AND",
                  value_field: "",
                  validations: {
                    data_type: "",
                  },
                  data_source: "VALUE",
                  entity_id: el.entity_id,
                });
              }
            });
            if (filters && filters.length) {
              bus.$emit("apply-custom-filter", {
                fields: this.mapDateFilters(filters),
              });
            } else {
              bus.$emit("apply-custom-filter", {
                reset: true,
              });
            }
          } else {
            bus.$emit("apply-custom-filter", {
              reset: true,
              entity_id: key.entity_id,
            });
          }
        } else {
          bus.$emit("apply-custom-filter", {
            reset: true,
            entity_id: key.entity_id,
            field_id: key.filter_field,
          });
        }
      } else {
        bus.$emit("apply-custom-filter", {
          reset: true,
        });
      }
      // if(key){
      //   bus.$emit("apply-custom-filter", {
      //     field: key
      //   });
      // }else{
      //   bus.$emit("apply-custom-filter", {
      //     reset: true
      //   });
      // }
    },
    getFilterFieldOptions(key) {
      if (key) {
        return this.allTemplateSelectFields.find((e) => e.key == key);
      }
      return {
        label: "field",
        options: [],
      };
    },
    setAllFieldsOptions() {
      this.filterLoading = true;
      if (
        this.getAllCompanyTemplatesData &&
        this.getAllCompanyTemplatesData.data
      ) {
        let fieldsMap = {};
        (this.getAllCompanyTemplatesData.data || []).forEach((element) => {
          let data = JSON.parse(JSON.stringify(element));
          fieldsMap[element._id] = this.getTemplateFieldsByType(data);
        });
        if (
          this.allDashboardstats.allCustomFilters &&
          this.allDashboardstats.allCustomFilters.length
        ) {
          (this.allDashboardstats.allCustomFilters || []).forEach((field) => {
            if (field?.filter_field && field?.filter_field.includes("#")) {
              let allFields = fieldsMap[field.filter_field.split("#")[0]];
              let selectedField = allFields.find(
                (e) => e.key == field.filter_field
              );
              if (selectedField) {
                let options = selectedField.options || [];
                if (
                  selectedField?.input_type == "ENTITY_VARIABLE" &&
                  selectedField?.global_variable_entity_field &&
                  selectedField.global_variable_entity_field.includes("#")
                ) {
                  let stAllFields =
                    fieldsMap[
                      selectedField.global_variable_entity_field.split("#")[0]
                    ];
                  let enSelectedField = stAllFields.find(
                    (e) => e.key == selectedField.global_variable_entity_field
                  );
                  options = enSelectedField.options || options;
                }
                this.allTemplateSelectFields.push({
                  label: selectedField.label,
                  options: options,
                  key: selectedField.key,
                  primary_fields: selectedField.primary_fields,
                });
              }
            }
          });
        }
        if (
          this.allDashboardstats.allMulCustomFilters &&
          this.allDashboardstats.allMulCustomFilters.length
        ) {
          (this.allDashboardstats.allMulCustomFilters || []).forEach(
            (field) => {
              if (
                field?.entities[0].filter_field &&
                field?.entities[0].filter_field.includes("#")
              ) {
                let allFields =
                  fieldsMap[field.entities[0].filter_field.split("#")[0]];
                let selectedField = allFields.find(
                  (e) => e.key == field.entities[0].filter_field
                );
                if (selectedField) {
                  let options = selectedField.options || [];
                  if (
                    selectedField?.input_type == "ENTITY_VARIABLE" &&
                    selectedField?.global_variable_entity_field &&
                    selectedField.global_variable_entity_field.includes("#")
                  ) {
                    let stAllFields =
                      fieldsMap[
                        selectedField.global_variable_entity_field.split("#")[0]
                      ];
                    let enSelectedField = stAllFields.find(
                      (e) => e.key == selectedField.global_variable_entity_field
                    );
                    options = enSelectedField.options || options;
                  }
                  this.allTemplateSelectFields.push({
                    label: selectedField.label,
                    options: options,
                    key: selectedField.key,
                  });
                }
              }
            }
          );
        }
      }
      this.filterLoading = false;
    },
    async fetchDependencies(filter) {
      if (
        !this.dependenciesData ||
        !this.dependenciesData[filter.integration_id] ||
        !this.dependenciesData[filter.integration_id][filter.dependency]
      ) {
        let params = {
          appName: filter.appName,
          connectionId: filter.connection_id,
          field: filter.dependency,
        };
        let selectedApplication = this.predefinedIntegrations.find(
          (e) => e.key == filter.appName
        );
        let hasDependency = null;
        let dependencies = this.getDependencies(selectedApplication).filter(
          (d) => d.key == filter.dependency
        );
        if (dependencies.length) {
          hasDependency = dependencies.find(
            (f) =>
              f.key == filter.dependency &&
              f.dependencyField &&
              f.dependencyField.length
          );
          let parameters = {};
          (hasDependency?.dependencyField || []).map((dep) => {
            let dependencyFilter = (
              this.allDashboardstats?.allIntegrationFilters || []
            ).find(
              (fil) =>
                fil.integration_id == filter.integration_id &&
                dep == fil.dependency
            );
            parameters[dep] = dependencyFilter.default_value;
          });
          params["parameters"] = parameters;
        }
        const response = await postAPICall(
          "POST",
          "integrations/service/get-user-data",
          params
        );
        console.log("response", response);
        if (response?.data && response.data.length) {
          this.$set(this.dependenciesData, filter.integration_id, {
            ...this.dependenciesData[filter.integration_id],
            ...{
              [filter.dependency]: response.data,
            },
          });
        }

        if ((!response?.data || !response.data.length) && hasDependency) {
          this.$set(this.dependenciesData, filter.integration_id, {
            ...this.dependenciesData[filter.integration_id],
            ...{
              [filter.dependency]: null,
            },
          });
          filter.default_value = "";
        }
      }
    },
  },
  data() {
    return {
      filterLoading: false,
      dependenciesData: {},
      loadingText: "Getting custom filters",
      customFilters: [],
      allTemplateSelectFields: [],
      dateFilter: {
        filter_field: "",
        selected_date_picker: "",
        entity_id: "",
        custom_date: "",
        custom_date_range: [],
        selected_month: "",
      },
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last 7 Days",
          value: "last_7_days",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
        {
          label: "Custom date",
          value: "custom_date",
        },
        {
          label: "Custom date range",
          value: "custom_date_range",
        },
        {
          label: "Month",
          value: "month",
        },
      ],
      filterValues: {},
      dependencyFilters: {},
    };
  },
  watch: {
    allDashboardstats: {
      handler() {
        this.setAllFieldsOptions();
        // this.dateFilter.selected_date_picker = "";

        (this.allDashboardstats?.allIntegrationFilters || []).map((e) => {
          this.fetchDependencies(e);
        });
        if(this.allDashboardstats.date_value){
        this.dateFilter.selected_date_picker = this.allDashboardstats.date_value;
        this.setDatePickerDate(this.dateFilter.selected_date_picker)
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.stats-container {
  // margin-bottom: 1.25em;
  display: flex;
  gap: 10px;
}
</style>
